import React from 'react';
import './App.css';
import Navbar from './components/00 - Navbar/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/01 - Home/Home';
import Standesamt from './components/02 - Standesamt/Standesamt';
import FreieTrauung from './components/03 - Freie Trauung/FreieTrauung';
import Anreise from './components/04 - Anreise/Anreise';
import AnreiseAuto from './components/04 - Anreise/AnreiseAuto';
import AnreiseBahn from './components/04 - Anreise/AnreiseBahn';
import AnreiseFlugzeug from './components/04 - Anreise/AnreiseFlugzeug';
import Unterkunft from './components/05 - Unterkunft/Unterkunft';
import InDerWoche from './components/06 - In der Woche/InDerWoche';
import InDerWocheSocializing from './components/06 - In der Woche/InDerWocheSocializing';
import InDerWocheTips from './components/06 - In der Woche/InDerWocheTipsBerlin';
import FAQ from './components/07 - FAQ/FAQ';
// import FotoUpload from './components/Foto Upload/FotoUpload';
import Footer from './components/99 - Footer/Footer'
import Impressum from './components/98 - Impressum/Impressum'

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/standesamt" element={<Standesamt />} />
          <Route path="/freietrauung" element={<FreieTrauung />} />
          <Route path="/anreise" element={<Anreise />} />
          <Route path="/anreise/auto" element={<AnreiseAuto />} />
          <Route path="/anreise/bahn" element={<AnreiseBahn />} />
          <Route path="/anreise/flugzeug" element={<AnreiseFlugzeug />} />
          <Route path="/unterkunft" element={<Unterkunft />} />
          <Route path="/in-der-woche" element={<InDerWoche />} />
          <Route path="/in-der-woche/socializing" element={<InDerWocheSocializing />} />
          <Route path="/in-der-woche/berlintips" element={<InDerWocheTips />} />
          {/* <Route path="/fotoupload" element={<FotoUpload />} /> */}
          <Route path="/faq" element={<FAQ />} />
          <Route path="/impressum" element={<Impressum />} />
        </Routes>
        <Footer /> {/* Add Footer here */}
      </div>
    </Router>
  );
}

export default App;