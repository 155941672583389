import React from 'react';

const InDerWocheTips = () => {
    return (
        <div className="outer-container">
            <div className="inner-container">
                <h1 className="my-heading-dancingscript">Unsere Tipps für euch in Berlin</h1>
                <p><br />
                    Berlin ist riesig und bietet für jeden etwas. Hier ein paar Empfehlungen von uns:<br /><br />

                    <strong>1.) Touri Programm</strong><br /><br />
                    Alexander Platz, Rotes Rathaus, Berliner Dom, Brandenburger Tor, Regierungsviertel, Siegessäule...<br />
                    <br />
                    In Berlin sind glücklicherweise alle aufgezählten Sehenswürdigkeiten nah beieinander. Mit der S9 könnt ihr bis zum Alexanderplatz fahren und von dort einen Spaziergang bis zur Siegessäule unternehmen. Auf einer ca. 5 km Strecke könnt ihr alle Attraktionen betrachten.<br /><br /><br />

                    <strong>2.) Strandbad Müggel See, Strandbad, Grünau & Strandbad Erkner</strong><br /><br />
                    Der August ist heiß und wer sich mal abkühlen möchte, kann entweder mit der Tram 61 zum Strandbad Müggelsee, mit der Tram 68 zum Strandbad Grünau oder mit dem Auto zum Strandbad Erkner fahren.  Die ersten beiden liegen im Bezirk Treptow Köpenick. Das Strandbad Erkner befindet sich in Brandenburg. Alle Strandbäder sind höchstens 20 -30 Minuten entfernt.<br /><br /><br />

                    <strong>3.) Altstadt Köpenick</strong><br /><br />
                    Die Altstadt Köpenick ist ein kleines Schmuckstück in Berlin. Man kann hier wunderbar spazieren, in einem der zahlreichen Restaurants flanieren oder an der Dahme bzw. Spree. entlang schlendern. Auch befindet sich hier unser Treffpunkt, die Schlossbrauerei Köpenick.<br /><br /><br />

                    <strong>4.) Markthalle 9/Holzmarkt/Oberbaumbrücke/ Berliner Mauer und Simon-Dach-Straße</strong><br /><br />
                    Im schönen, aber auch sehr verrückten Bezirk Kreuzberg befindet sich die Markthalle 9. In der Nähe des Görlitzer Bahnhof, kann man sich durch diverse, verschiedene Köstlichkeiten probieren.<br /><br />
                    Von hier aus, gibt es die Möglichkeit über die Oberbaumbrücke Richtung Warschauerstraße zu spazieren. Wer will, kann auch noch einen kurzen Abstecher zur Berliner Mauer mit den berühmten Bildern machen. Würde man den Mauerabschnitt weiterlaufen, kommt man zum Ostbahnhof, wo sich der Holzmarkt befindet. Ein toller Künstlermarkt, der mit zahlreichen Streetfood und Getränkeständen zum Verweilen einlädt.<br /><br />
                    Richtung Warschauer Straße befindet sich das RAW Gelände mit vielen Clubs- und Biergärten sowie die Simon-Dach-Straße. Hier befinden sich zahlreiche, Restaurants und Bars mit sehr spannenden und faszinierenden Menschen.<br /><br /><br />

                    <strong>5.) Ecke U Eberswalder Straße und Currywurstbude Konpoke</strong><br /><br />
                    Die Eberswalder Straße befindet sich im Prenzlauer Berg. Gerade an diesem U-Bahnhof befindet sich einiges an Attraktionen, wie der Comedy Club Mad- Monkey, der starke August (Bar mit Burlesque- und Varieté-Shows), zahlreiche Clubs, Restaurants und Bars. Auch die berühmte Currywurst Bude Konopke befindet sich hier, wo es Original-Berliner Currywurst gibt.
                    <div className="image-container">
                        <img src="/photos/inderwoche_tips_image_01.jpg" alt="White Spreelounge" className="responsive-image" />
                    </div>
                </p>
            </div>
        </div>
    );
};

export default InDerWocheTips;