import React from 'react';
import './FAQ.css'

//Inhalt:
// Geschenk (Geldgeschenk)
// Dresscode
// Essen
// ...

const FAQ = () => {
  return (
    <div className="outer-container">
      <div className="inner-container">
        <h1 className="my-heading-dancingscript">FAQ - Frequently Asked Questions</h1>
        <div className="faq-item">
          <p className="faq-question">Gibt es einen Dresscode?</p>
          <p className="faq-answer">Wir freuen uns, euch in sommerlich, festlicher Kleidung begrüßen zu dürfen. </p>
        </div><br />
        <div className="faq-item">
          <p className="faq-question">Gibt es unterschiedliche Essensvarianten?</p>
          <p className="faq-answer">Ob Fleisch, vegetarisch oder vegan: jeder soll an unserer Hochzeit das Essen können, was er bevorzugt. Bitte gebt uns doch dazu eine entsprechende Rückmeldung.</p>
        </div><br />
        <div className="faq-item">
          <p className="faq-question">Was können wir euch schenken?</p>
          <p className="faq-answer">Wir sind Jung und brauchen das Geld ;-)</p>
        </div><br />
        <div className="image-container">
          <img src="/photos/faq_image_01.jpg" alt="White Spreelounge" className="responsive-image" />
        </div>
      </div>
    </div>
  );
};

export default FAQ;