import React from 'react';

function Impressum() {
    return (
        <div className="outer-container">
            <div className="inner-container">
                <h1>Impressum</h1>
                <p>Dominik Hanke</p>
                <p>Büchnerweg 38</p>
                <p>12489 Berlin</p>
                <p>Deutschland</p>

                <p>Tel.: +4915111529928</p>
                <p>E-Mail: hanke-dominik@t-online.de</p>

                <h1>Datenschutzerklärung</h1>

                <h2>1) Einleitung und Kontaktdaten des Verantwortlichen</h2>

                <h3>1.1</h3>
                <p>
                    Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns für Ihr Interesse. Im Folgenden
                    informieren wir Sie über den Umgang mit Ihren personenbezogenen Daten bei der Nutzung unserer
                    Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie persönlich identifiziert
                    werden können.
                </p>

                <h3>1.2</h3>
                <p>
                    Verantwortlicher für die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung
                    (DSGVO) ist Dominik Hanke, Büchnerweg 38, 12489 Berlin, Deutschland, Tel.: +4915111529928, E-Mail:
                    hanke-dominik@t-online.de. Der für die Verarbeitung von personenbezogenen Daten Verantwortliche ist
                    diejenige natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und
                    Mittel der Verarbeitung von personenbezogenen Daten entscheidet.
                </p>

                <h2>2) Datenerfassung beim Besuch unserer Website</h2>

                <h3>2.1</h3>
                <p>
                    Bei der bloß informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns
                    anderweitig Informationen übermitteln, erheben wir nur solche Daten, die Ihr Browser an den
                    Seitenserver übermittelt (sog. „Server-Logfiles“). Wenn Sie unsere Website aufrufen, erheben wir die
                    folgenden Daten, die für uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:
                </p>
                <ul>
                    <li>Unsere besuchte Website</li>
                    <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
                    <li>Menge der gesendeten Daten in Byte</li>
                    <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
                    <li>Verwendeter Browser</li>
                    <li>Verwendetes Betriebssystem</li>
                    <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
                </ul>
                <p>
                    Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an
                    der Verbesserung der Stabilität und Funktionalität unserer Website. Eine Weitergabe oder anderweitige
                    Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles
                    nachträglich zu überprüfen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.
                </p>

                <h3>2.2</h3>
                <p>
                    Diese Website nutzt aus Sicherheitsgründen und zum Schutz der Übertragung personenbezogener Daten
                    und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw.
                    TLS-Verschlüsselung. Sie können eine verschlüsselte Verbindung an der Zeichenfolge „https://“ und dem
                    Schloss-Symbol in Ihrer Browserzeile erkennen.
                </p>

                <h2>3) Kontaktaufnahme</h2>
                <p>
                    Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene
                    Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem
                    jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum Zweck der Beantwortung
                    Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration
                    gespeichert und verwendet.
                </p>
                <p>
                    Rechtsgrundlage für die Verarbeitung dieser Daten ist unser berechtigtes Interesse an der Beantwortung
                    Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines
                    Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre
                    Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht. Dies ist der Fall, wenn sich aus den
                    Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und sofern keine
                    gesetzlichen Aufbewahrungspflichten entgegenstehen.
                </p>

                <h2>4) Seitenfunktionalitäten</h2>
                <h3>Google Web Fonts</h3>
                <p>
                    Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten sogenannte Web Fonts des folgenden
                    Anbieters: Google Ireland Limited, Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland
                </p>
                <p>
                    Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browser-Cache, um Texte und
                    Schriftarten korrekt anzuzeigen und stellt eine direkte Verbindung zu den Servern des Anbieters her.
                    Hierbei werden bestimmte Browserinformationen, einschließlich Ihrer IP-Adresse, an den Anbieter
                    übermittelt.
                </p>
                <p>
                    Daten können zudem übermittelt werden an: Google LLC, USA
                </p>
                <p>
                    Die Verarbeitung von personenbezogenen Daten im Zuge der Verbindungsaufnahme mit dem Anbieter der
                    Schriftarten wird nur dann vollzogen, wenn Sie uns gemäß Art. 6 Abs. 1 lit. a DSGVO dazu Ihre ausdrückliche
                    Einwilligung erteilt haben. Sie können Ihre erteilte Einwilligung jederzeit mit Wirkung für die Zukunft
                    widerrufen, indem Sie diesen Dienst über das auf der Webseite bereitgestellte „Cookie-Consent-Tool“
                    deaktivieren. Falls Ihr Browser keine Web Fonts unterstützt, wird eine Standardschrift von Ihrem Computer
                    genutzt.
                </p>
                <p>
                    Für Datenübermittlungen in die USA hat sich der Anbieter dem EU-US-Datenschutzrahmen (EU-US Data
                    Privacy Framework) angeschlossen, das auf Basis eines Angemessenheitsbeschlusses der Europäischen
                    Kommission die Einhaltung des europäischen Datenschutzniveaus sicherstellt.
                </p>

                <h2>5) Rechte des Betroffenen</h2>
                <p>
                    5.1 Das geltende Datenschutzrecht gewährt Ihnen gegenüber dem Verantwortlichen hinsichtlich der
                    Verarbeitung Ihrer personenbezogenen Daten die nachstehenden Betroffenenrechte (Auskunfts- und
                    Interventionsrechte), wobei für die jeweiligen Ausübungsvoraussetzungen auf die angeführte
                    Rechtsgrundlage verwiesen wird:
                </p>
                <ul>
                    <li>Auskunftsrecht gemäß Art. 15 DSGVO;</li>
                    <li>Recht auf Berichtigung gemäß Art. 16 DSGVO;</li>
                    <li>Recht auf Löschung gemäß Art. 17 DSGVO;</li>
                    <li>Recht auf Einschränkung der Verarbeitung gemäß Art. 18 DSGVO;</li>
                    <li>Recht auf Unterrichtung gemäß Art. 19 DSGVO;</li>
                    <li>Recht auf Datenübertragbarkeit gemäß Art. 20 DSGVO;</li>
                    <li>Recht auf Widerruf erteilter Einwilligungen gemäß Art. 7 Abs. 3 DSGVO;</li>
                    <li>Recht auf Beschwerde gemäß Art. 77 DSGVO.</li>
                </ul>

                <h3>5.2 WIDERSPRUCHSRECHT</h3>
                <p>
                    WENN WIR IM RAHMEN EINER INTERESSENABWÄGUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES
                    ÜBERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS
                    GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH
                    MIT WIRKUNG FÜR DIE ZUKUNFT EINZULEGEN.
                </p>
                <p>
                    MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN
                    DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZWÜRDIGE
                    GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN KÖNNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND
                    GRUNDFREIHEITEN ÜBERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER
                    VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.
                </p>
                <p>
                    WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN
                    SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER
                    DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE KÖNNEN DEN WIDERSPRUCH WIE OBEN
                    BESCHRIEBEN AUSÜBEN.
                </p>
                <p>
                    MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN
                    DATEN ZU DIREKTWERBEZWECKEN.
                </p>

                <h2>6) Dauer der Speicherung personenbezogener Daten</h2>
                <p>
                    Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen
                    Rechtsgrundlage, am Verarbeitungszweck und – sofern einschlägig – zusätzlich anhand der jeweiligen
                    gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen).
                </p>
                <p>
                    Bei der Verarbeitung von personenbezogenen Daten auf Grundlage einer ausdrücklichen Einwilligung
                    gemäß Art. 6 Abs. 1 lit. a DSGVO werden die betroffenen Daten so lange gespeichert, bis Sie Ihre
                    Einwilligung widerrufen.
                </p>
                <p>
                    Existieren gesetzliche Aufbewahrungsfristen für Daten, die im Rahmen rechtsgeschäftlicher bzw.
                    rechtsgeschäftsähnlicher Verpflichtungen auf der Grundlage von Art. 6 Abs. 1 lit. b DSGVO verarbeitet
                    werden, werden diese Daten nach Ablauf der Aufbewahrungsfristen routinemäßig gelöscht, sofern sie
                    nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein
                    berechtigtes Interesse an der Weiterspeicherung fortbesteht.
                </p>
                <p>
                    Bei der Verarbeitung von personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO werden
                    diese Daten so lange gespeichert, bis Sie Ihr Widerspruchsrecht nach Art. 21 Abs. 1 DSGVO ausüben, es sei
                    denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
                    Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                    Verteidigung von Rechtsansprüchen.
                </p>
                <p>
                    Bei der Verarbeitung von personenbezogenen Daten zum Zwecke der Direktwerbung auf Grundlage von Art.
                    6 Abs. 1 lit. f DSGVO werden diese Daten so lange gespeichert, bis Sie Ihr Widerspruchsrecht nach Art. 21
                    Abs. 2 DSGVO ausüben.
                </p>
                <p>
                    Sofern sich aus den sonstigen Informationen dieser Erklärung über spezifische Verarbeitungssituationen
                    nichts anderes ergibt, werden gespeicherte personenbezogene Daten im Übrigen dann gelöscht, wenn sie
                    für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.
                </p>


            </div>
        </div>
    );
}

export default Impressum;
